import React from 'react';
import Errors from '@components/Errors/Errors';
import Page from '@components/Page';
import { serverSideTranslations } from '@util/i18nUtils';
import { Layout } from '@components/layout/Layout';
import { defaultNamespaces } from '../src/constants';

const Custom404Page = ({ locale }) => {
  return (
    <Page>
      <Errors statusCode={404} locale={locale} />
    </Page>
  );
};

Custom404Page.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
};

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale === 'acon' ? 'en' : locale, [...defaultNamespaces])),
    },
  };
};

export default Custom404Page;
